/* eslint-disable @typescript-eslint/camelcase */

import { BookingCommissionRequest } from "@/data/payload/contracts/BookingCommissionRequest";
import { BookingCommissionDetail } from "@/domain/entities/BookingCommission";
import { parseInputFloatToFloat } from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class AddBookingCommissionRequest implements BookingCommissionRequest {
  data: BookingCommissionDetail;
  constructor(data: BookingCommissionDetail) {
    this.data = data;
  }
  public toJSON(): string {
    return JSON.stringify({
      tier_base: this.data.commissionTierBase,
      tier_price_reference: this.data.commissionPriceTierReference,
      commission_price_reference: this.data.commissionPriceReference,
      commission_type: this.data.commissionType,
      commission_quantifier: this.data.commissionQuantifier,
      booking_source: this.data.commissionBookingSource,
      applied_to: this.data.commissionFor,
      shipment_prefix: this.data.commissionShipmentId
        .map((item: any) => {
          return item.prefix.toLowerCase();
        })
        .join(","),
      origin_city_code: this.data.commissionOriginCity.value,
      destination_city_code: this.data.commissionDestinationCity.value,
      product_type: this.data.commissionProduct.name.toLowerCase(),
      commodity_code: this.data.commissionCommodity.code.toLowerCase(),
      tier_calculation: this.data.commissionCalculationTier.map((item: any) => {
        return {
          tier_base_start: parseInputFloatToFloat(item.startAmount),
          tier_base_end: parseInputFloatToFloat(item.endAmount),
          tier_base_commission_amount: parseInputFloatToFloat(
            item.commissionAmount
          ),
          tier_base_branch_commission_amount: parseInputFloatToFloat(
            item.commissionAmountBranch
          ),
          tier_base_pickup_commission_amount: parseInputFloatToFloat(
            item.commissionAmountPickup
          ),
          tier_base_pickup_publish_rate_commission_amount: parseInputFloatToFloat(
            item.commissionAmountPickupPublishRate
          )
        };
      })
    });
  }
}

export class EditBookingCommissionRequest implements BookingCommissionRequest {
  data: BookingCommissionDetail;
  constructor(data: BookingCommissionDetail) {
    this.data = data;
  }
  public toJSON(): string {
    return JSON.stringify({
      bc_id: this.data.commissionId,
      tier_base: this.data.commissionTierBase,
      tier_price_reference: this.data.commissionPriceTierReference,
      commission_price_reference: this.data.commissionPriceReference,
      commission_type: this.data.commissionType,
      commission_quantifier: this.data.commissionQuantifier,
      tier_calculation: this.data.commissionCalculationTier.map((item: any) => {
        return {
          tier_base_start: parseInputFloatToFloat(item.startAmount),
          tier_base_end: parseInputFloatToFloat(item.endAmount),
          tier_base_commission_amount: parseInputFloatToFloat(
            item.commissionAmount
          ),
          tier_base_branch_commission_amount: parseInputFloatToFloat(
            item.commissionAmountBranch
          ),
          tier_base_pickup_commission_amount: parseInputFloatToFloat(
            item.commissionAmountPickup
          ),
          tier_base_pickup_publish_rate_commission_amount: parseInputFloatToFloat(
            item.commissionAmountPickupPublishRate
          )
        };
      })
    });
  }
}

export class RequestBookingCommissionList {
  page = 1;
  limit = 10;
  search = "";
  source = "";
  commissionFor = "";
  shipmentPrefix = "";
  originCityCode = "";
  destinationCityCode = "";
  productType = "";
  commodityCode = "";
  isTotalData = false;
  version = "1";

  constructor(fields?: Partial<RequestBookingCommissionList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
