/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  BookingCommissionDetail,
  BookingCommissionEntities
} from "@/domain/entities/BookingCommission";
import { BookingCommissionPresenter } from "../presenters/BookingCommissionPresenter";
import { Pagination } from "@/domain/entities/Pagination";
import { ShipmentBookingPresenter } from "@/app/ui/presenters/ShipmentBookingPresenter";
import { container } from "tsyringe";
import { ShipmentPrefix } from "@/domain/entities/ShipmentBooking";
import {
  AddBookingCommissionRequest,
  EditBookingCommissionRequest,
  RequestBookingCommissionList
} from "@/data/payload/api/BookingCommissionApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface BookingCommissionState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  bookingCommissionList: BookingCommissionEntities;
  bookingCommissionDetail: BookingCommissionDetail;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "booking_commission"
})
class BookingCommissionStore extends VuexModule
  implements BookingCommissionState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public bookingCommissionList = new BookingCommissionEntities(
    new Pagination(1, 20),
    []
  );
  public bookingCommissionDetail = new BookingCommissionDetail();
  public search = "";
  public advanceFilterData = {
    bookingSource: { name: "", value: "" },
    appliedTo: { name: "", value: "" },
    shipmentPrefix: "",
    cityOriginCode: "",
    cityDestinationCode: "",
    shipmentProduct: "",
    commodity: ""
  };
  public bookingCommissionForm = new BookingCommissionDetail();

  @Action
  public fetchBookingCommissionList(params: RequestBookingCommissionList) {
    this.setLoading(true);
    const presenter = container.resolve(BookingCommissionPresenter);
    presenter
      .getBookingCommissionList(params)
      .then((res: BookingCommissionEntities) => {
        this.setBookingCommisionList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public initBookingCommissionList() {
    this.setBookingCommisionList(
      new BookingCommissionEntities(new Pagination(1, 20), [])
    );
    this.setAdvanceFilterData({
      bookingSource: { name: "", value: "" },
      appliedTo: { name: "", value: "" },
      shipmentPrefix: "",
      cityOriginCode: "",
      cityDestinationCode: "",
      shipmentProduct: "",
      commodity: ""
    });
    this.setSearch("");
  }

  @Action
  public getBookingCommissionList() {
    this.fetchBookingCommissionList(
      new RequestBookingCommissionList({
        page: this.bookingCommissionList.pagination.page,
        limit: this.bookingCommissionList.pagination.limit,
        search: this.search,
        source: this.advanceFilterData.bookingSource.value,
        commissionFor: this.advanceFilterData.appliedTo.value,
        shipmentPrefix: this.advanceFilterData.shipmentPrefix,
        originCityCode: this.advanceFilterData.cityOriginCode,
        destinationCityCode: this.advanceFilterData.cityDestinationCode,
        productType: this.advanceFilterData.shipmentProduct,
        commodityCode: this.advanceFilterData.commodity,
        isTotalData: true,
        version: "2"
      })
    );
  }

  @Action
  public fetchBookingCommissionDetail(params: { id: any; type: string }) {
    this.setLoading(true);
    const presenter = container.resolve(BookingCommissionPresenter);
    presenter
      .getBookingCommissionDetail(Number(params.id))
      .then((res: BookingCommissionDetail) => {
        if (params.type === "edit") {
          this.setBookingCommissionForm(res);
        } else {
          this.setBookingCommissionDetail(res);
        }
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async fetchListShipmentPrefix() {
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter
      .getListShipmentPrefix()
      .then((resp: ShipmentPrefix[]) => {
        return resp;
      })
      .catch(() => {
        return [];
      });
  }

  @Action
  public async addBookingCommission() {
    this.setSuccessSave(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BookingCommissionPresenter);
    return presenter
      .addBookingCommission(
        new AddBookingCommissionRequest(this.bookingCommissionForm)
      )
      .then((resp: ResponsePayload) => {
        this.setSuccessSave(true);
        return resp;
      })
      .catch((err: any) => {
        this.setSuccessSave(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Gagal !", () =>
            this.addBookingCommission()
          )
        );
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async editBookingCommission() {
    this.setSuccessSave(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BookingCommissionPresenter);
    return presenter
      .editBookingCommission(
        new EditBookingCommissionRequest(this.bookingCommissionForm)
      )
      .then((resp: ResponsePayload) => {
        this.setSuccessSave(true);
        return resp;
      })
      .catch((err: any) => {
        this.setSuccessSave(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", () =>
            this.editBookingCommission()
          )
        );
        return new ResponsePayload({ message: "internet" });
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async searchAction(value: string) {
    await this.setSearch(value);
    await this.setFirstPage();
    await this.getBookingCommissionList();
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Mutation
  private async setFirst() {
    this.bookingCommissionList.pagination.page = 1;
  }

  @Mutation
  public setAdvanceFilterData(value: any) {
    this.advanceFilterData = value;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setBookingCommisionList(data: BookingCommissionEntities) {
    this.bookingCommissionList = data;
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setErrorCause(err: string) {
    this.errorCause = err;
  }

  @Mutation
  public setBookingCommissionDetail(data: BookingCommissionDetail) {
    this.bookingCommissionDetail = data;
  }

  @Mutation
  public setBookingCommissionForm(data: BookingCommissionDetail) {
    this.bookingCommissionForm = data;
  }

  public successSave = false;
  @Mutation
  public setSuccessSave(bool: boolean) {
    this.successSave = bool;
  }
}

export const BookingCommissionController = getModule(BookingCommissionStore);
